import React from 'react';
import {
  Box,
  Container,
  CssBaseline,
  Grid,
  Typography,
  Link
} from '@material-ui/core';
import {
  createMuiTheme,
  ThemeProvider,
  makeStyles,
} from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import yellow from '@material-ui/core/colors/yellow';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: grey[900],
    },
    secondary: {
      main: yellow[400],
    },
    text: {
      primary: '#fff',
    },
  },
});

const useStyles = makeStyles({
  '@global': {
    'html,body,#root': {
      height: '100%',
      width: '100%',
      margin: 0,
      padding: 0,
      backgroundColor: theme.palette.primary.main,
    },
  },
  image: {
    width: '100%',
    //cursor: 'pointer',
    //'&:hover': {
    //  background: 'rgba(0,0,0,0.03)',
    //},
  },
  project: {
    display: 'flex',
    flexDirection: 'column',
  },
  projectText: {
    flexGrow: 1,
  },
});

function App() {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth='md' className={classes.container}>
        <Box pt={12}>
          <Typography variant="h2">
            Hi, I&rsquo;m Marc.
          </Typography>
        </Box>
        <Box pt={4} maxWidth={300}>
          <Typography>
            I code all sorts of things. I love talking about math and physics.
            I&rsquo;m training for a half-marathon. I occasionally build wooden
            things.
          </Typography>
        </Box>
        <Box pt={16}>
          <Typography variant="h2">
            Things I&rsquo;ve done (for fun)
          </Typography>
        </Box>
        <Box pt={4}>
          <Grid container spacing={4}>
            <Grid item sm={6} xs={12} className={classes.project}>
              <Typography variant="h5">
                Should I Exercise
              </Typography>
              <Typography className={classes.projectText}>
                A calculator for projecting cash flow from incentive stock options (ISOs).
              </Typography>
              <Link href='https://shouldiexercise.com' target='_blank'>
                <img className={classes.image} src="shouldiexercise.png" alt="should i exercise my incentive stock options screenshot" />
              </Link>
            </Grid>
            <Grid item sm={6} xs={12} className={classes.project}>
              <Typography variant="h5">
                Cube Trivia
              </Typography>
              <Typography className={classes.projectText}>
                An online multiplayer trivia builder and peer-to-peer game.
              </Typography>
              <Link href='https://cubetrivia.com' target='_blank'>
                <img className={classes.image} src="cubetrivia.png" alt="cube trivia screenshot" />
              </Link>
            </Grid>
            <Grid item sm={6} xs={12} className={classes.project}>
              <Typography variant="h5">
                Pokeytest
              </Typography>
              <Typography className={classes.projectText}>
                An internet speed test that captures pokemon based on the results.
              </Typography>
              <Link href='https://pokeytest.com' target='_blank'>
                <img className={classes.image} src="pokeytest.png" alt="pokemon speed test screenshot" />
              </Link>
            </Grid>
          </Grid>
        </Box>
        <Box pt={16}>
          <Typography variant="h2">
            Places I&rsquo;ve worked
          </Typography>
        </Box>
        <Box pt={4} maxWidth={600}>
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <Link href="https://www.citadel.com/our-teams/engineering-team" target="_blank" color="secondary">
                Citadel
              </Link>
              <br />
              <Typography variant="caption">
                May 2023 - Present
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>
                I&rsquo;m primarily building a transaction monitoring system with peak message
                rates ~500k/s sustained. For data processing and SLA tracking, the pipeline
                runs on kafka, python and kdb (previously clickhouse). I built a metrics platform
                for trend analytics in python and bigquery.
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Link href="https://www.digitalocean.com/about" target="_blank" color="secondary">
                DigitalOcean
              </Link>
              <br />
              <Typography variant="caption">
                October 2015 - February 2023
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>
                I worked on Observability platforms. I designed and built streaming data
                applications running across hundreds of physical servers, processing several
                hundred megabits per second from tens of thousands of machines. I mostly
                worked with Go, Mesos, Docker, and Elasticsearch.
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Link href="https://www.bloomberg.com/company" target="_blank" color="secondary">
                Bloomberg
              </Link>
              <br />
              <Typography variant="caption">
                February 2011 - April 2015
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>
                I started building out management tools and analytics platforms for an
                internally developed, distributed sqlite-based RDBMS. I then moved onto
                building an analytics platform for the company's internally developed
                microservice infrastructure. Eventually I helped improve our automated
                log- and metric-based alerting systems and build out our centralized
                logging platform on Elasticsearch. I mostly worked with C++ and
                JavaScript.
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Link href="http://www.sfp.caltech.edu/programs/ligo_surf" target="_blank" color="secondary">
                Caltech
              </Link>
              <br />
              <Typography variant="caption">
                Summer 2010
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>
                I worked on a model for calculating the theoretical second-order error
                terms of estimated parameters from gravitational waves signals detected
                by LIGO. I learned the value and impact of a metrics-driven decision making
                ideology. I mostly worked with pen and paper.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default App;
